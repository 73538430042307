export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/(app)/(dashboard)/(main)": [9,[2,3]],
		"/(app)/admin": [23,[2,7]],
		"/(app)/admin/case_preview/[id]": [~24,[2,7]],
		"/(app)/admin/test_preview/[id]": [~25,[2,7]],
		"/auth": [26,[8]],
		"/auth/err": [27,[8]],
		"/auth/magic": [28,[8]],
		"/auth/sudo": [29,[8]],
		"/privacy_policy": [~30],
		"/(app)/(dashboard)/profile": [10,[2,3]],
		"/(app)/(dashboard)/school/[school]": [11,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/about": [~12,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/companies": [~13,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/peers": [~14,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/peers/[peer]": [~15,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/recordings": [~16,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/tasks": [~17,[2,3,4]],
		"/(app)/(dashboard)/school/[school]/tasks/[task_id]": [~18,[2,3,4]],
		"/(app)/(dashboard)/selection/[school]": [19,[2,3,5]],
		"/(app)/(dashboard)/selection/[school]/about": [~21,[2,3,5]],
		"/(app)/(dashboard)/selection/[school]/take_case": [~22,[2,3,5]],
		"/(app)/(dashboard)/selection/[school]/[take_test=test_type]": [~20,[2,3,5],[,,,6]],
		"/terms": [~31]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';